import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.min.css'
import 'flatpickr/dist/themes/airbnb.css'

// Connects to data-controller="flatpickr"
export default class extends Controller {
  connect() {
    flatpickr(this.element, {
      enableTime: true,
      // dateFormat: 'm/d/Y h:i K', TODO: このフォーマットだと登録できない
      time_24hr: false,
      minDate: 'today'
    })
  }
}
