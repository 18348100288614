// Entry point for the build script in your package.json
import 'lightgallery/css/lightgallery.css'

import { Application } from '@hotwired/stimulus'
import '@hotwired/turbo-rails'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import Dropdown from '@stimulus-components/dropdown'
import Notification from '@stimulus-components/notification'
import Reveal from '@stimulus-components/reveal'
import { Tabs } from 'tailwindcss-stimulus-components'
import PasswordVisibility from '@stimulus-components/password-visibility'
import CharacterCounter from '@stimulus-components/character-counter'
import Lightbox from '@stimulus-components/lightbox'

const application = Application.start()
application.register('dropdown', Dropdown)
application.register('notification', Notification)
application.register('reveal', Reveal)
application.register('password-visibility', PasswordVisibility)
application.register('character-counter', CharacterCounter)
application.register('lightbox', Lightbox)
application.register('tabs', Tabs)

// eslint-disable-next-line no-undef
const context = require.context('./controllers', true, /\.js$/)
application.load(definitionsFromContext(context))

application.debug = true // TODO: Release時にDebugをfalseに変更
